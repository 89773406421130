import React from 'react';

import PopupFooter from './PopupFooter';
import tag from './Analytics';


function Twitter(props) {

  tag('twitter');

  return (
    <div class="gp-popup gp-popup-twitter">
      <div class="gp-close-icon" onClick={props.onClose}>
        <span class="icon">
          <i class="fa fa-window-close"></i>
        </span>
      </div>
      <div class="gp-content gp-no-counter">
        <div class="content">
          <p><b>Je soutiens les #9deCattenom sur Twitter</b></p>
          <p>
            Pour afficher votre soutien aux activistes de Greenpeace sur Twitter :
            <ol>
              <li>
                <b>Connectez-vous</b> sur votre compte Twitter
              </li>
              <li>
                Vérifiez que la <b>géolocalisation</b> est bien activée.<br/>
                Rendez-vous dans <i>Paramètres et confidentialité > Confidentialité et sécurité > Localisation > Localisation exacte</i>
              </li>
              <li>
                Tweetez avec le hashtag <b><a target="_blank" href="https://twitter.com/hashtag/9deCattenom" rel="noopener noreferrer">#9deCattenom</a></b>
              </li>
            </ol>
          </p>
          <a
            target="_blank"
            className="button fa-twitter gp-share-twitter"
            href="https://twitter.com/intent/tweet?text=La%20place%20des%20militant%C2%B7es%20de%20%40greenpeacefr%20n%E2%80%99est%20pas%20en%20prison%20!%20Je%20soutiens%20les%20%239deCattenom%20jug%C3%A9%C2%B7es%20en%20appel%20%C3%A0%20Metz%20pour%20avoir%20d%C3%A9nonc%C3%A9%20le%20risque%20nucl%C3%A9aire.%20Rejoignez%20le%20mouvement%20!%20https%3A%2F%2Fwww.greenpeace.fr%2Fproces-metz%2F&url=https%3A%2F%2F"
            rel="noopener noreferrer"
          >
            <span class="icon">
              <i class="fab fa-twitter"></i>
            </span>
            <span>Je tweete</span>
          </a>

        </div>
        <PopupFooter/>
      </div>
    </div>
  )
}

export default Twitter;
