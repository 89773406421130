import React, { Component, PureComponent } from 'react';
import ReactTable from 'react-table';
import classnames from 'classnames';
import 'react-table/react-table.css';

import APIClient from './Api';
import EditPopup from './EditPopup';
import Parameters from './Parameters';
import { generateTweetLink } from './Utils';


const api = new APIClient();


class BackOfficeTable extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  async fetchData() {
    const data = await api.getBackOfficeData();
    this.setState({data: data});
  }

  async componentDidMount() {
    await this.fetchData();
  }

  render() {
    const columns = [
    {
      Header: 'Sél.',
      accessor: 'id',
      sortable: false,
      maxWidth: 100,
      Cell: props => {
        const contribId = props.original.id,
        moderated = props.original.moderated,
        onChange = () => {this.props.onRowSelectToggle(contribId, moderated)};
        return <input
                  type="checkbox"
                  checked={this.props.selectedRows[contribId] !== undefined}
                  onChange={onChange}></input>
        }
    },
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 100,
    }, {
      Header: 'Prénom',
      accessor: 'firstname'/*,
      Cell: props => <span className='number'>{props.value}</span> // Custom cell components!*/
    }, {
      /*id: 'Prénom', // Required because our accessor is not a string*/
      Header: 'Nom',
      accessor: 'lastname'
      /*accessor: d => d.friend.name // Custom value accessors!*/
    },
    {
      Header: 'Modération',
      accessor: 'moderated',
      Cell: (props) => {
        const contribId = props.original.id,
          onClick = () => {this.props.onEditEntry(contribId)};
        return props.value ?
        (<button class="button is-danger" onClick={onClick}>Non affiché sur le site</button>)
      : (<button class="button is-info" onClick={onClick}>Affiché sur le site</button>)
      },
      minWidth: 200,
      className: 'gp-bo-moderation'
    },
    {
      Header: 'Message',
      accessor: 'message',
      minWidth: 200
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      minWidth: 200
    },
    {
      Header: 'Opt-in',
      accessor: 'optin',
      Cell: props => <span>{props.value ? "Oui" : "Non"}</span>
    },
    {
      Header: 'Envoyé à Engaging Networks',
      accessor: 'sent_to_engaging_networks',
      Cell: props => <span>{props.value ? "Oui" : "Non"}</span>
    },
    {
      Header: 'Tweet',
      accessor: 'tweet_id',
      Cell: (props) => { return generateTweetLink(props.value) }
    }
  ]
    return (
          <ReactTable
            data={this.state.data}
            columns={columns}
            defaultPageSize={500}
            className="gp-bo-table -striped -highlight"
            previousText="Précédent"
            nextText="Suivant"
            loadingText="Chargement..."
            noDataText="Pas de données"
            showPageSizeOptions={false}
            showPageJump={false}
            pageText="Page"
            ofText="de"
            rowsText="lignes"
          />);
  }
}


class BackOffice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editing: null,
      selectedRows: {}
    };
    this.table = React.createRef();
  }

  onEditEntry = (contribId) => {
    this.setState({editing: contribId});
  }

  onClose = () => {
    this.setState({editing: null});
    // Refresh table
    this.table.current.fetchData();
  }

  onRowSelectToggle = (contribId, moderated) => {
    let rows = { ...this.state.selectedRows };
    if (rows[contribId] !== undefined) {
      delete rows[contribId];
    } else {
      rows[contribId] = moderated;
    }
    this.setState({selectedRows: rows});
  }

  getNumberOfSelectedRows() {
    return Object.keys(this.state.selectedRows).length;
  }

  toModerateOrNot() {
    // Depending on the existing status of the majority of selected rows, we moderate or not
    let nbModerated = Object.values(this.state.selectedRows).map((a) => a ? 1 : 0).reduce((a, b) => a + b)
    return nbModerated < this.getNumberOfSelectedRows() / 2
  }

  getModerationActionLabel() {
    let nb = this.getNumberOfSelectedRows();
    return 'Faire ' + (this.toModerateOrNot() ? 'disparaître' : 'apparaître') + ' les ' + nb + ' soutiens sélectionnés';
  }

  moderate = async () => {
    let moderateOrNot = this.toModerateOrNot();
    await api.moderateSeveral(Object.keys(this.state.selectedRows), moderateOrNot);
    this.setState({selectedRows: {}});
    // Refresh table
    this.table.current.fetchData();
  }

  render() {
    return (
      <div className="gp-bo-content">
        { this.state.editing != null ?
          <EditPopup
            contribId={this.state.editing}
            onClose={this.onClose}/> : '' }
        <h1 className="title">Administration Carte Soutien</h1>
        <div>
          <div>
            <span className="gp-bo-subtitle">Liste des soutiens</span>
            { this.getNumberOfSelectedRows() > 1 ? <button
                                                  className={classnames("button gp-bo-multimoderation", this.toModerateOrNot() ? "is-danger" : "is-info")}
                                                  onClick={this.moderate}>{this.getModerationActionLabel()}</button> : ''}
          </div>
          <BackOfficeTable
            onRowSelectToggle={this.onRowSelectToggle}
            onEditEntry={this.onEditEntry}
            ref={this.table}
            selectedRows={this.state.selectedRows}/>
          <hr/>
          <Parameters />
        </div>
      </div>
    )
  }

}

export default BackOffice;
