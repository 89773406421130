import axios from 'axios';


const client = axios.create({
  baseURL: '/',
  json: true
});


class APIClient {

  createContrib(contrib) {
    return client.post('/api/contribs/', contrib).then(resp => {
        return resp.data
      }
    ).catch((error) => {
      console.log(error);
      if (error.response.status === 403) {
        error.reason = 'CAPTCHA';
      }
      throw error;
    });

    // TODO expired captcha
  }

  getNumberOfContribs = async () => {
    let resp = await client.get('/api/contribs/');
    return resp.headers['x-total-count'] || 0;
  }

  getContrib(id) {
    return client.get('/api/contribs/' + id).then(resp => {
        return resp.data
      }
    );
  }

}

export default APIClient;
