import React, {Component} from 'react';

import APIClient from './Api';

const client = new APIClient();


class Counter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      count: 0
    };
  }

  async componentDidMount() {
    const count = await client.getNumberOfContribs();
    this.setState({count: count});
  }

  render() {
    return (
      <div class="gp-counter">
        {this.state.count !== 0 ? this.state.count + ' messages' : ''}
      </div>
    )
  }
}

export default Counter;
