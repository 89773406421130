import React, { Component } from 'react';

import APIClient from './Api';


const api = new APIClient();


class Parameters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modifOK: false
    };
  }

  async fetchData() {
    const data = await api.getBackOfficeParams();
    this.setState({
      ENGAGING_NETWORKS_PAGE_ID: data.ENGAGING_NETWORKS_PAGE_ID,
      TWITTER_HASHTAGS: data.TWITTER_HASHTAGS
    });
  }

  async componentDidMount() {
    await this.fetchData();
  }

  handleChange = (event) => {
    // Validate current changed or blurred field, so that the user gradually knows what is required
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      modifOK: false
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log(this.state);
    await api.setBackOfficeParam('ENGAGING_NETWORKS_PAGE_ID', this.state.ENGAGING_NETWORKS_PAGE_ID);
    await api.setBackOfficeParam('TWITTER_HASHTAGS', this.state.TWITTER_HASHTAGS);
    this.setState({modifOK: true})
  }

  render() {
    return (
      <div className="gp-bo-identifiers">
        <h2 className="gp-bo-subtitle">Paramètres</h2>
        <form className="box" onSubmit={this.handleSubmit}>
          <div className="gp-fields">
            <div className="field">
              <label className="label">Identifiant Engaging Networks</label>
              <div className="control has-icons-right">
                <input
                  type="text"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={this.state.ENGAGING_NETWORKS_PAGE_ID}
                  name="ENGAGING_NETWORKS_PAGE_ID"
                  maxLength="80"
                  className="input"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Hashtags Twitter (séparés par des virgules)</label>
              <div className="control has-icons-right">
                <input
                  type="text"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={this.state.TWITTER_HASHTAGS}
                  name="TWITTER_HASHTAGS"
                  maxLength="80"
                  className="input"
                />
              </div>
            </div>
            <div className="field">
              <button
                type="submit"
                className="button is-link gp-button-form-submit"
               >
                Modifier
              </button>
            </div>
            {
              this.state.modifOK ? (<div className="field"><p className="help is-success">Modification OK</p></div>) : ''
            }
          </div>
        </form>
      </div>);
  }
}

export default Parameters;
