import React from 'react';
import classnames from 'classnames';


function ActButton(props) {

  return (
      <div className={classnames("gp-act-button", props.className)} onClick={props.onAct}>
        <span>agir !</span>
      </div>
    )
}

export default ActButton;
