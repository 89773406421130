import React from 'react';
import { Link } from "react-router-dom";


function PopupFooter(props) {

  return (
      <div class="gp-popup-footer">
        <div class="is-vcentered gp-nav">
          <Link to={props.url || '/'}>{props.text || 'Retour'}</Link>
        </div>
        <div class="is-vcentered gp-logo">
          <img alt="Greenpeace" src="logo-gp.png"/>
        </div>
      </div>
    )
  }

export default PopupFooter;
