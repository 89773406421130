import React from 'react';
import classnames from 'classnames';


function ResumeFormButton(props) {

  return (
      <div className={classnames("gp-resume-form-button", props.className)} onClick={props.onAct}>
        <span>Continuer la saisie</span>
      </div>
    )
}

export default ResumeFormButton;
