import React, { Component } from 'react';

import APIClient from './Api';
import { generateTweetLink } from './Utils';
import pointeur from '../assets/Pointeur-couleur.png';


const api = new APIClient();


class EditPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null
    };
  }

  async fetchData() {
    const data = await api.getBackOfficeContrib(this.props.contribId);
    this.setState({data: data});
  }

  async componentDidMount() {
    await this.fetchData();
    if (window.mapboxgl) {
      window.mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
      var map = new window.mapboxgl.Map({
        container: 'gp-bo-minimap', // container id
        style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
        center: [this.state.data.lng, this.state.data.lat], // starting position [lng, lat]
        zoom: 14 // starting zoom,
      });
      map.on('load', () => {
        // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
        // We use the inline data image built by webpack.
        // If we run into load problems, we can still move the icon to "public" and use process.env.PUBLIC_URL + 'Pointeur-couleur.png'
        map.loadImage(pointeur, (error, image) => {
          if (error) throw error;
          map.addImage('heart', image);
          map.addSource('point', {
            type: 'geojson',
            data: {"type": "FeatureCollection",
                   "features": [
                     {"type": "Feature", "geometry":
                      {"type": "Point", "coordinates": [this.state.data.lng, this.state.data.lat]},
                       //"properties": {"id": 1, "tweet_id": ""}
                     }
                   ]}
          });
          map.addLayer({
            id: 'contrib',
            type: 'symbol',
            source: 'point',
            layout: {
              "icon-image": "heart",
              "icon-size": 0.5,
              "icon-anchor": "bottom"
            }
          });
        });
      });
    }
  }

  doModerate = async () => {
    await api.moderate(this.props.contribId, true);
    await this.fetchData();
  }

  dontModerate = async () => {
    await api.moderate(this.props.contribId, false);
    await this.fetchData();
  }

  handleClose = () => {
    this.props.onClose()
  }

  render() {
    return (
      <div class="modal is-active">
        <div class="modal-background" onClick={this.handleClose}></div>
        <div class="modal-content">
          <div class="box">
            {this.state.data ? (
              <div>
                <h2 className="title">Modération du message n°{this.state.data.id}</h2>
                <div className="gp-fields">
                  <div className="field box">
                    <label className="label">Prénom Nom</label>
                    <div>{this.state.data.firstname} {this.state.data.lastname}</div>
                  </div>
                  <div className="field box">
                    <label className="label">Message</label>
                    <div>{this.state.data.message}</div>
                  </div>
                  {
                    this.state.data.tweet_id ?
                    <div className="field box">
                      <label className="label">Twitter</label>
                      <div>{generateTweetLink(this.state.data.tweet_id)}</div>
                    </div>
                    : ''
                  }
                  <div className="field box">
                    <label className="label">Localisation</label>
                    <div id="gp-bo-minimap"></div>
                  </div>
                  <div className="field box">
                    <label className="label">Modération</label>
                    <div>
                      {
                        this.state.data.moderated ?
                        <button className="button is-info" onClick={this.dontModerate}>Faire réapparaître le message sur le site</button> :
                        <button className="button is-danger" onClick={this.doModerate}>Faire disparaître le message du site</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            ) : 'Chargement en cours...'
            }
          </div>
        </div>
        <button
          class="modal-close is-large"
          aria-label="close"
          onClick={this.handleClose}></button>
      </div>
    )
  }
}

export default EditPopup;
