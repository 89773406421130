import React, {Component} from 'react';

import Counter from './Counter';
import PopupFooter from './PopupFooter';
import tag from './Analytics';


class MoreInfo extends Component {

  constructor(props) {
    super(props);
    tag('moreinfo');
  }

  render() {
    return (
      <div class="gp-popup gp-popup-moreinfo">
        <div class="gp-close-icon" onClick={this.props.onClose}>
          <span class="icon">
            <i class="fa fa-window-close"></i>
          </span>
        </div>
        <div class="gp-content">
          <div class="content">
            <p>
              Le 12 octobre 2017, des militant·es de Greenpeace ont pénétré
              dans la centrale nucléaire de Cattenom, en Moselle,
              et déclenché un feu d’artifice au pied de la piscine d’entreposage
              de combustibles usés.&nbsp;
              <b>Par cette action non violente</b>, ces femmes et ces hommes
              ont dénoncé la vulnérabilité de bâtiments lourdement chargés de radioactivité.
              Greenpeace avait déjà pointé à plusieurs reprises ces failles, sans réaction.
            </p>
            <p>
              <b>Pour avoir lancé l'alerte, huit militant·e·s et un salarié ont été jugé·es
              en première instance à Thionville.</b>&nbsp;
              Sept ont écopé de peines de cinq mois de prison avec sursis,
              tandis que deux ont été condamnés à deux mois de prison ferme -&nbsp;
              <b>une première dans l’histoire de Greenpeace France</b>.
            </p>
            <p>
              Ces lourdes sanctions ne sont pas acceptables, alors que leur action s'est
              faite au nom de l’intérêt général. Ils et elles l’ont fait pour nous !
            </p>
            <p>
              <b>Les 9 de Cattenom ont décidé de faire appel : leur procès se tiendra à Metz le 30 octobre 2019.</b>
            </p>
            <p>
              Ils et elles ont dénoncé une menace qui nous concerne toutes et tous.&nbsp;
              <b>Leur place n’est pas en prison.</b>
            </p>
            <p>
              Pour en savoir plus sur la mobilisation de soutien aux 9 de Cattenom : <br/>
              <a
                target="_blank"
                href="https://www.greenpeace.fr/proces-metz/"
                rel="noopener noreferrer">https://www.greenpeace.fr/proces-metz/</a>
            </p>
          </div>
          <p class="gp-legal">
            Cette carte de soutien est éditée et hébergée par Greenpeace France.
            Rendez-vous sur le site de Greenpeace France pour en savoir plus sur le&nbsp;
            <a
              target="_blank"
              href="https://www.greenpeace.fr/copyright/"
              rel="noopener noreferrer">Copyright</a>&nbsp;
            et les&nbsp;
            <a
              target="_blank"
              href="https://www.greenpeace.fr/mentions-legales/"
              rel="noopener noreferrer">
              Conditions générales d’utilisation
            </a>
            .
          </p>
          <PopupFooter/>
        </div>
        <Counter/>
      </div>
    )
  }
}

export default MoreInfo;
