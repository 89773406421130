import React from 'react';

import PopupFooter from './PopupFooter';
import Share from './Share';
import tag from './Analytics';


function Success(props) {

  tag('formsuccess');

  return (
    <div class="gp-popup gp-popup-success">
      <div class="gp-close-icon" onClick={props.onClose}>
        <span class="icon">
          <i class="fa fa-window-close"></i>
        </span>
      </div>
      <div class="gp-content gp-no-counter">
        <div class="content">
          <p><b>Merci beaucoup de votre soutien aux 9 de Cattenom.</b>&nbsp;
          Votre message apparaîtra sur le site dans quelques minutes.</p>
          <p>Ils et elles ont agi au nom de l’intérêt général,
          pour dénoncer le risque nucléaire.&nbsp;
          <b>Soyons encore plus nombreux·ses à leurs côtés, à Metz le 30 octobre et dans le monde.</b>&nbsp;
          Faites passer le mot sur les réseaux sociaux :
          </p>
        </div>
        <Share/>
        <PopupFooter/>
      </div>
    </div>
  )
}

export default Success;
