import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import TagManager from 'react-gtm-module'
import classnames from 'classnames';
import queryString from 'query-string';

import './App.scss';
import BackOffice from './backoffice/BackOffice';
import Form from './Form';
import Welcome from './Welcome';
import MoreInfo from './MoreInfo';
import Success from './Success';
import Twitter from './Twitter';
import ActButton from './ActButton';
import ResumeFormButton from './ResumeFormButton';
import APIClient from './Api';
import pointeur from './assets/Pointeur-couleur.png';


const api = new APIClient();
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    dataLayerName: 'PageDataLayer'
}
TagManager.initialize(tagManagerArgs);


class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      popinDisplayed: true,
      // Store initial query string (for utm_* parameters)
      initialQS: props.location ? queryString.parse(props.location.search) : ''
    };
  }

  componentDidMount() {
    if (window.mapboxgl) {
      window.mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
      var map = new window.mapboxgl.Map({
        container: 'gp-map', // container id
        style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
        center: [2.349014, 48.864716], // starting position [lng, lat]
        zoom: 7 // starting zoom,
      });

      map.addControl(new window.mapboxgl.NavigationControl(), 'bottom-right');

      map.on('load', () => {
        // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
        // We use the inline data image built by webpack.
        // If we run into load problems, we can still move the icon to "public" and use process.env.PUBLIC_URL + 'Pointeur-couleur.png'
        map.loadImage(pointeur, function(error, image) {
          if (error) throw error;
          map.addImage('heart', image);
          map.addSource('points', {
            type: 'geojson',
            data: '/api/contribs/'
          });
          map.addSource('newly-added-point', {
            type: 'geojson',
            data: {"type": "FeatureCollection", "features": []}
          });
          map.addLayer({
            id: 'contribs',
            type: 'symbol',
            source: 'points',
            layout: {
              "icon-image": "heart",
              "icon-size": 0.5,
              "icon-anchor": "bottom"
            }
          });
          map.addLayer({
            id: 'newly-added-contrib',
            type: 'symbol',
            source: 'newly-added-point',
            layout: {
              "icon-image": "heart",
              "icon-size": 0.5,
              "icon-anchor": "bottom"
            }
          });
          // https://docs.mapbox.com/mapbox-gl-js/example/popup-on-click/
          var clickOnContrib = function (e) {
            var feat = e.features[0];
            var coordinates = feat.geometry.coordinates.slice();
            var contribId = feat.properties.id != null ? feat.properties.id : '';
            var tweetId = feat.properties.tweet_id != null ? feat.properties.tweet_id : '';

            let elementHtmlId = 'gp-contrib-content-' + contribId,
                description = '<div id="' + elementHtmlId + '"></div>';

            // From mapbox example:
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
              coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Set popup constraints if on mobile or tablet
            let maxWidth = window.innerWidth > 768 ? 'none' : '240px';
            let twitterOptions = window.innerWidth > 768 ? {lang: 'fr'} : {lang: 'fr', width: 240};

            if (tweetId !== '' || contribId !== '') {
              new window.mapboxgl.Popup({maxWidth: maxWidth, anchor: 'center'})
              .setLngLat(coordinates)
              .setHTML(description)
              .addTo(map);

              const tagManagerClickOnMarkerArgs = {
                  dataLayer: {
                      event: "standard",
                      eventCategory: "carte-soutien",
                      eventAction: "click",
                      eventLabel: "clic sur la contribution " + contribId
                  },
                  dataLayerName: 'PageDataLayer'
              }
              TagManager.dataLayer(tagManagerClickOnMarkerArgs);

              if (tweetId !== '') {
                console.log('Creating twitter popup contents...')
                // https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/scripting-factory-functions
                window.twttr.widgets.createTweet(tweetId,
                  document.getElementById(elementHtmlId),
                  twitterOptions
                ).then(function (element) {
                  console.log("Twitter widget " + tweetId + " created.")
                });
              } else {
                // console.log('Creating regular popup contents...')
                api.getContrib(contribId).then(function (data) {
                  /* use react to generate html ? */
                  document.getElementById(elementHtmlId).innerHTML =
                    '<div class="gp-contrib-name">' + data.name + '</div>' +
                    '<div class="gp-contrib-message">' + data.message + '</div>';
                  //  console.log("Regular popup " + contribId + " created.");
                });
              }
            }

          }
          map.on('click', 'contribs', clickOnContrib);
          map.on('click', 'newly-added-contrib', clickOnContrib);

          // Change the cursor to a pointer when the mouse is over the places layer.
          map.on('mouseenter', 'places', function () {
            map.getCanvas().style.cursor = 'pointer';
          });

          // Change it back to a pointer when it leaves.
          map.on('mouseleave', 'places', function () {
            map.getCanvas().style.cursor = '';
          });
        });
      });
      window.map = map;
    }
  }

  onPopupClose = () => {
    this.setState({popinDisplayed: false});
  }

  onButtonClick = () => {
    this.setState({popinDisplayed: true});
  }

  render() {
    return (
      <div>
        <Switch location={this.props.location}>
          <Route path="/add">
            <ResumeFormButton onAct={this.onButtonClick} className={this.state.popinDisplayed ? "gp-hidden" : ""}/>
          </Route>
          <Route path="/">
            <ActButton onAct={this.onButtonClick} className={this.state.popinDisplayed ? "gp-hidden" : ""}/>
          </Route>
        </Switch>
        <div className={classnames("gp-responsive-container", this.state.popinDisplayed ? "": "gp-hidden")}>
          <TransitionGroup>
              {/*
                See: https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/examples/Animation/index.js
                no different than other usage of
              CSSTransition, just make sure to pass
              `location` to `Switch` so it can match
              the old location as it animates out
          */}
            <CSSTransition
                    key={this.props.location.key}
                    classNames="alert"
                    timeout={300}
                  >
              <Switch location={this.props.location}>
                <Route path="/moreinfo">
                  <MoreInfo onClose={this.onPopupClose}/>
                </Route>
                <Route path="/twitter">
                  <Twitter onClose={this.onPopupClose}/>
                </Route>
                <Route path="/add">
                  <Form
                    onClose={this.onPopupClose}
                    qs={this.state.initialQS}/>
                </Route>
                <Route path="/success">
                  <Success onClose={this.onPopupClose}/>
                </Route>
                <Route path="/">
                  <Welcome onClose={this.onPopupClose}/>
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      <div id="gp-map"/>
    </div>
    );
  }
}

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/backoffice">
          <BackOffice/>
        </Route>
        <Route
          render={({ location }) => (
            <Main location={location}/>
          )}
        />
      </Switch>
    </Router>
  );
}
