import TagManager from 'react-gtm-module';


function tag(pageName) {
  const tagManagerArgs = {
      dataLayer: {
          event: 'VirtualPageview',
          virtualPageURL: window.location.href,
          virtualHostName: window.location.origin,
          virtualPageTitle: pageName
      },
      dataLayerName: 'PageDataLayer'
  }
  TagManager.dataLayer(tagManagerArgs);
}

export default tag;
