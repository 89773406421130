import axios from 'axios';


const client = axios.create({
  baseURL: '/',
  json: true
});


class APIClient {

  getBackOfficeData() {
    return client.get('/api/backoffice/').then(resp => {
        return resp.data
      }
    );
  }

  getBackOfficeContrib(id) {
    return client.get('/api/backoffice/' + id).then(resp => {
        return resp.data
      }
    );
  }

  moderate = async (id, moderate) => {
    let res = await client.patch('/api/backoffice/' + id, {moderated: moderate});
    return res;
  }

  moderateSeveral = async (ids, moderate) => {
    for (let id of ids) {
      await this.moderate(id, moderate);
    }
  }

  getBackOfficeParams() {
    return client.get('/api/backoffice/params/').then(resp => {
        return resp.data
      }
    );
  }

  setBackOfficeParam(id, value) {
    return client.patch('/api/backoffice/params/' + id, {[id]: value});
  }

}

export default APIClient;
