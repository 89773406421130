import React, {Component} from 'react';
import { ReactComponent as InstagramLogo } from './assets/instagram.svg';
import { ReactComponent as FacebookLogo } from './assets/facebook.svg';
import { ReactComponent as TwitterLogo } from './assets/twitter.svg';
import { ReactComponent as WhatsappLogo } from './assets/whatsapp.svg';

class Share extends Component {

  render() {
    return (
      <div>
        <div class="columns is-fullwidth">
          <div class="column">
            <a
              target="_blank"
              className="button is-fullwidth gp-share-twitter"
              href="https://twitter.com/intent/tweet?text=La%20place%20des%20militant%C2%B7es%20de%20%40greenpeacefr%20n%E2%80%99est%20pas%20en%20prison%20!%20Je%20soutiens%20les%20%239deCattenom%20jug%C3%A9%C2%B7es%20en%20appel%20%C3%A0%20Metz%20pour%20avoir%20d%C3%A9nonc%C3%A9%20le%20risque%20nucl%C3%A9aire.%20Rejoignez%20le%20mouvement%20!%20https%3A%2F%2Fwww.greenpeace.fr%2Fproces-metz%2F&url=https%3A%2F%2F"
              rel="noopener noreferrer"
            >
              <span class="icon">
                <TwitterLogo/>
              </span>
              <span>Twitter</span>
            </a>
          </div>
          <div class="column">
            <a
              target="_blank"
              className="button is-fullwidth gp-share-facebook"
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.greenpeace.fr%2Fproces-metz%2F%3Futm_campaign%3DNuke-Instit"
              rel="noopener noreferrer"
            >
              <span class="icon">
                <FacebookLogo/>
              </span>
              <span>Facebook</span>
            </a>
          </div>
        </div>
        <div class="columns is-fullwidth">
          <div class="column">
            <a
              target="_blank"
              className="button is-fullwidth gp-share-instagram"
              href="https://www.instagram.com/p/B3hIbMSIDkJ/"
              rel="noopener noreferrer"
            >
              <span class="icon">
                <InstagramLogo/>
              </span>
              <span>Instagram</span>
            </a>
          </div>
          <div class="column">
            <a
              target="_blank"
              className="button is-fullwidth gp-share-whatsapp"
              href="whatsapp://send?text=Bonjour%20!%20Je%20viens%20d'envoyer%20un%20message%20de%20soutien%20aux%209%20de%20Cattenom%20%3A%20ces%20militant%C2%B7es%20de%20Greenpeace%20risquent%20la%20prison%20pour%20avoir%20d%C3%A9nonc%C3%A9%20des%20failles%20de%20s%C3%A9curit%C3%A9%20dans%20la%20centrale%20nucl%C3%A9aire%20de%20Cattenom%20et%20lanc%C3%A9%20l'alerte%20sur%20le%20risque%20nucl%C3%A9aire.%20Leur%20proc%C3%A8s%20en%20a"
              rel="noopener noreferrer"
            >
              <span class="icon">
                <WhatsappLogo/>
              </span>
              <span>Whatsapp</span>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Share;
