import React, {Component} from 'react';
import { Link } from "react-router-dom";

import Counter from './Counter';
import PopupFooter from './PopupFooter';
import tag from './Analytics';


class Welcome extends Component {

  constructor(props) {
    super(props);
    tag('home');
  }

  render() {
    return (
      <div class="gp-popup gp-popup-welcome">
        <div class="gp-close-icon" onClick={this.props.onClose}>
          <span class="icon">
            <i class="fa fa-window-close"></i>
          </span>
        </div>
        <div class="gp-content">
          <h1 class="title">
            <span class="gp-subtitle-1">Soutenez les #9deCattenom</span>
            <br/>
            <span class="gp-subtitle-2">Leur place n'est pas en prison !</span>
          </h1>
          <div class="columns is-fullwidth buttons">
            <div class="column gp-column-welcome-twitter">
              <Link to="/twitter" className="button is-fullwidth is-medium gp-button-welcome-twitter">
              <span class="icon">
                <i class="fab fa-twitter"></i>
              </span>
              <span>Je tweete</span>
              </Link>
            </div>
            <div class="column gp-column-welcome-form">
              <Link to="/add" className="button is-fullwidth is-medium gp-button-welcome-form">J'envoie un message</Link>
            </div>
          </div>
          <div class="content">
            Affichez votre soutien aux huit activistes et au salarié de Greenpeace
            jugé·es en appel <b>à Metz le 30 octobre</b>.
            <br/>
            Ces militant·es ont dénoncé des
            failles de sécurité de la centrale nucléaire de Cattenom.
            <br/>
            Ils et elles l’ont fait pour nous.
          </div>

          <PopupFooter url="moreinfo" text="En savoir plus"/>

        </div>
        <Counter/>
      </div>
    )
  }
}

export default Welcome;
