import React from 'react';


function generateTweetLink(tweetId) {
  return tweetId != null && tweetId !== '' ?
    (<a target="_blank" rel="noopener noreferrer" href={"https://twitter.com/i/web/status/" + tweetId}>Post sur twitter</a>)
    : ''
}

export { generateTweetLink };
