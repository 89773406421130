import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import mbxClient from '@mapbox/mapbox-sdk';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import ReCAPTCHA from "react-google-recaptcha";

import countries from './assets/countries.json';
import APIClient from './Api';
import tag from './Analytics';


const api = new APIClient();
const baseClient = mbxClient({ accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN });
const geocodingClient = mbxGeocoding(baseClient);
const recaptchaRef = React.createRef();


function createOrUpdatePositionMarker(marker, center) {
  if (marker === null) {
    var el = document.createElement('div');
    el.className = 'gp-marker';
    marker = new window.mapboxgl.Marker(el,
      {
        draggable: true,
        anchor: 'bottom'
      })
      .setLngLat(center)
      .addTo(window.map);
    function onDragEnd() {
      var lngLat = marker.getLngLat();
      console.log(lngLat)
    }
    marker.on('dragend', onDragEnd);
  }
  marker.setLngLat(center);

  window.map.flyTo({center: center});
  return marker;
}

function addNewlyAddedPoint(marker, contribId) {
  // Create a geojson point
  var markerPoint = {
    "type": "FeatureCollection",
    "features": [{
      "type": "Feature",
      "properties": {
        "id": contribId,
        "tweet_id": ''
      },
      "geometry": {
        "type": "Point",
        "coordinates": [marker.getLngLat().lng, marker.getLngLat().lat]
      }
    }]
  };
  // Add it to special source
  window.map.getSource('newly-added-point').setData(markerPoint);
  // Remove marker
  marker.remove();
}

function applyRandomDeviation(pos) {

  const MAX_DEVIATION = process.env.REACT_APP_MAX_DEVIATION_FROM_CITY_CENTER; // Meters around original point

  let dy = Math.floor(Math.random() * MAX_DEVIATION * 2) - MAX_DEVIATION,
    dx = Math.floor(Math.random() * MAX_DEVIATION * 2) - MAX_DEVIATION;

  console.log(dy);
  console.log(dx);

  var lng = pos[0],
    lat = pos[1];

  // https://stackoverflow.com/questions/7477003/calculating-new-longitude-latitude-from-old-n-meters
  const r_earth = 6371000.0;
  var newLat  = lat  + (dy / r_earth) * (180 / Math.PI),
    newLng = lng + (dx / r_earth) * (180 / Math.PI) / Math.cos(lat * Math.PI/180);

  return new window.mapboxgl.LngLat(newLng, newLat);
}


class Form extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstname: '', firstnameValid: true,
       lastname: '', lastnameValid: true,
       hidename: false,
       country: 'France',
       city: '', cityValid: true,
       message: '', messageValid: true,
       email: '', emailValid: true,
       optin: false,
       pos: null, posValid: true,
       captchaValid: true,
       sendTried: false,
       sendOK: false
     };
     this.mandatory = ['firstname', 'lastname', 'city', 'message', 'email', 'pos', 'captcha'];
     tag('form');
  }

  // Form and fields stuff

  testField(name, value) {
    // All fields are valid if not empty, except for email which is optional if user doesn't opt in
    if (name === 'email' && !this.state.optin) {
      return true;
    }
    return value != null && value !== '';
  }

  validateFieldWithCurrentValue(name) {
    if (name == null) {
      throw Error('name cannot be null');
    }
    let valid = this.testField(name, this.state[name]);
    this.setState({[name + 'Valid']: valid});
    return valid;
  }

  validateFieldsWithCurrentValues() {
    var res =  this.mandatory.map(
      (name, index) => {
        return this.validateFieldWithCurrentValue(name);
      });
    // Note: don't test states, they have not been updated yet
    return res.every((x) => x);
  }

  handleChange = (event) => {
    // Validate current changed or blurred field, so that the user gradually knows what is required
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      [name + 'Valid']: this.testField(name, value),
      sendTried: false
    });

    // Special case for 'city': validate 'pos' too if valid
    if (name === 'city' && this.testField(name, value) ) {
      this.validateFieldWithCurrentValue('pos');
    }
  }

  handleCaptchaChange = (value) => {
    this.setState({
      captcha: value,
      captchaValid: this.testField('captcha', value)
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ sendTried: false, sendOK: false });
    console.log(this.state);
    if (this.validateFieldsWithCurrentValues()) {  // Force validation
      var data = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        hidename: this.state.hidename,
        country: this.state.country,
        city: this.state.city,
        message: this.state.message,
        email: this.state.email,
        lng: this.state.pos.getLngLat().lng,
        lat: this.state.pos.getLngLat().lat,
        optin: this.state.optin,
        captcha: this.state.captcha,
        utm_source: this.props.qs ? this.props.qs.utm_source : '',
        utm_medium: this.props.qs ? this.props.qs.utm_medium : '',
        utm_campaign: this.props.qs ? this.props.qs.utm_campaign : '',
        utm_content: this.props.qs ? this.props.qs.utm_content : '',
        utm_term: this.props.qs ? this.props.qs.utm_term : ''
      };
      console.log(data);
      api.createContrib(data).then( (data) =>
        {
          this.setState({
            sendTried: true,
            sendOK: true
          });
          console.log(data);
          try {
            addNewlyAddedPoint(this.state.pos, data.id);
            this.setState({pos: null});
          } catch(err) {
            console.log('Error adding newly created contrib to map:');
            console.log(err);
          }
        }).catch( (err) =>  {
          if (err.reason === 'CAPTCHA') {
            // Captcha has expired server side
            console.log('captcha error');
            recaptchaRef.current.reset();
            this.handleCaptchaChange('');
          }
          this.setState({
            sendTried: true,
            sendOK: false
          });
        }
      );
    } else {
      console.log('Fields not validated!');
    }
  }

  isValid (name) {
    return this.state[name + 'Valid'];
  }

  isAllValid () {
    return this.mandatory.every((x) => { return this.isValid(x) });
  }

  fieldClass (name, otherClassName) {
    return this.isValid(name) ? otherClassName : otherClassName + " is-danger";
  }

  fieldIcon (name) {
    return this.isValid(name) ? "" : (
      <span className="icon is-small is-right">
        <i className="fas fa-exclamation-triangle"></i>
      </span>);
  }

  // Locate stuff

  // https://www.freecodecamp.org/news/react-binding-patterns-5-approaches-for-handling-this-92c651b5af56/
  locateBrowser = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({pos: createOrUpdatePositionMarker(this.state.pos, [position.coords.longitude, position.coords.latitude])});
    });
  }

  locateCity = () => {
    if (!this.validateFieldWithCurrentValue('city')) {
      // Force user to enter a city, and exit
      return;
    }
    geocodingClient.forwardGeocode({
      query: this.state.city + ', ' + this.state.country,
      limit: 1
    })
      .send()
      .then(response => {
        const match = response.body;
        console.log(match);
        if (response && response.body && response.body.features && response.body.features.length) {
          var feature = response.body.features[0];
          var center = applyRandomDeviation(feature.center);
          this.setState({
            pos: createOrUpdatePositionMarker(this.state.pos, center),
            posValid: true
          });
          if (window.innerWidth <= 768) {
            // Close popin on mobile & tablet so that the user can see the marker
            this.props.onClose();
          }
        }
      }); // TODO ERROR
  }

  // Render stuff

  render() {
    if (this.state.sendTried && this.state.sendOK ){
      return (<Redirect to="/success"/>)
    }
    return (
      <div className="gp-popup gp-popup-form">
        <div class="gp-close-icon" onClick={this.props.onClose}>
          <span class="icon">
            <i class="fa fa-window-close"></i>
          </span>
        </div>
        <form className="gp-content gp-no-counter" onSubmit={this.handleSubmit}>
          <h2 className="title">Je soutiens les 9 de Cattenom</h2>
          <div className="gp-fields">
            <div className="field">
              <label className="label">Votre prénom</label>
              <div className="control has-icons-right">
                <input
                  type="text"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={this.state.firstname}
                  name="firstname"
                  maxLength="80"
                  className={this.fieldClass('firstname', 'input')}
                />
                { this.fieldIcon('firstname') }
              </div>
            </div>
            <div className="field">
              <label className="label">Votre nom</label>
              <div className="control has-icons-right">
                <input
                  type="text"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={this.state.lastname}
                  name="lastname"
                  maxLength="80"
                  className={this.fieldClass('lastname', 'input')}
                />
                { this.fieldIcon('lastname') }
              </div>
            </div>
            <div className="field">
              <div className="control">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                    value={this.state.hidename}
                    name="hidename"/>
                  Cochez si vous ne souhaitez pas qu'il apparaisse
                </label>
              </div>
            </div>

            <div className="field">
              <label className="label">Pays ou région</label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                    value={this.state.country}
                    name="country">
                    {
                      countries.map( (val, index) => { return (<option value={val.label}>{val.label}</option>) })
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Ville</label>
              <div class="columns is-fullwidth gp-city-columns">
                <div class="column is-two-thirds gp-city-input-column">
                  <div className="control has-icons-right">
                    <input
                      type="text"
                      onChange={this.handleChange}
                      onBlur={this.handleChange}
                      value={this.state.city}
                      name="city"
                      maxLength="80"
                      className={this.fieldClass('city', 'input')}
                    />
                    { this.fieldIcon('city') }
                  </div>
                </div>
                <div class="column gp-city-locate-column">
                  <button
                      type="button"
                      onClick={this.locateCity}
                      className={this.fieldClass('pos', 'button is-fullwidth gp-button-add-locate')}
                      >
                      {
                        this.state.pos != null ? (
                          <span>
                            <span class="icon">
                              <i class="fas fa-check-circle gp-locate-icon"></i>
                            </span>
                            <span>Confirmé</span>
                          </span>
                        ) : <span>Confirmer</span>
                      }
                  </button>
                </div>
              </div>
              { this.isValid('city') && !this.isValid('pos') ?
                (<p className="help is-danger">Cliquez sur Confirmer pour localiser votre ville, puis déplacez le marqueur où vous le souhaitez</p>) : ""}
            </div>

            <div className="field">
              <label className="label">Votre message</label>
              <div className="control has-icons-right">
                <textarea
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={this.state.message}
                  name="message"
                  maxLength="280"
                  className={this.fieldClass('message', 'textarea')}
                  placeHolder="240 caractères maximum"
                  ></textarea>
                { this.fieldIcon('message') }
              </div>
            </div>

            <div className="field">
              <label className="label">Votre e-mail (optionnel)</label>
              <div className="control has-icons-left has-icons-right">
                <input
                  type="email"
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                  value={this.state.email}
                  name="email"
                  maxLength="120"
                  className={this.fieldClass('email', 'input')}
                  placeholder="Entrez votre e-mail"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
                { this.fieldIcon('email') }
              </div>
            </div>
            { this.state.email != null && this.state.email !== '' ?
              (<div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      onChange={this.handleChange}
                      onBlur={this.handleChange}
                      value={this.state.optin}
                      name="optin"/>
                    En cochant cette case, j'accepte de recevoir des infos sur le procès et des communications de Greenpeace.
                    Mes données personnelles collectées resteront strictement confidentielles.
                    Elles ne seront ni vendues ni échangées conformément aux mentions légales de Greenpeace.&nbsp;
                    <a target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.greenpeace.fr/mentions-legales/droits-des-personnes/">L’exercice de mes droits</a>, dont la désinscription, est possible à tout moment.
                  </label>
                </div>
              </div>) : ''
            }

            <div class="field">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={this.handleCaptchaChange}
                ref={recaptchaRef}
                theme="light"
                hl="fr"
                className={this.fieldClass('captcha', 'gp-captcha')}
              />
            </div>

            <div className="field">
              { this.isAllValid() ? "" : (<p className="help is-danger">Merci de compléter les champs obligatoires</p>) }
            </div>

            <div className="field">
              <div class="columns is-fullwidth gp-send-columns">
                <div class="column gp-send-send-column is-two-thirds">
                  <div className="control">
                    <button
                      type="submit"
                      className={
                        // TODO use classnames package
                        (this.state.sendTried && !this.state.sendOK ? "is-danger " : "")
                        + "button is-link is-fullwidth gp-button-form-submit"
                      }>
                      Valider
                    </button>
                  </div>
                </div>
                <div class="column gp-send-cancel-column">
                  <div className="control">
                    <Link to="/" className="button is-link is-fullwidth gp-button-form-cancel">Annuler</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="field">
              { this.state.sendTried && !this.state.sendOK ? (
                <p className="help is-danger">Une erreur s'est produite lors de l'envoi de votre message, veuillez réessayer</p>
              ) : "" }
            </div>

          </div>
        </form>
      </div>
    );
  }
}

export default Form;
